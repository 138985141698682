
// COUNTER

// COUNTER
function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}

function initializeClock(endtime) {
    const timer = document.querySelector('.counter-input');

    function updateClock() {
        const t = getTimeRemaining(endtime);

        minutes = ('0' + t.minutes).slice(-2);
        seconds = ('0' + t.seconds).slice(-2);

        timer.value = minutes + ':' + seconds;
        // console.log(minutes + ':' + seconds);

        if (t.total <= 0) {
            clearInterval(timeinterval);
        }
    }

    updateClock();
    const timeinterval = setInterval(updateClock, 1000);
}

 