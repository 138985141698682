class Dropdown {

    constructor(options) {

        this.selector = options.container;

        this.dropdownInputField = this.selector.querySelector('.dropdown__input');
        this.dropdownList = this.selector.querySelector('.dropdown__list');
        this.dropdownListItems = this.selector.querySelectorAll('.dropdown__item');
        this.container = this.selector.closest('.dropdown');
      
        this.container.addEventListener('mousedown', this.onMouseDown.bind(this));
        this.dropdownInputField.addEventListener('blur', this.onBlur.bind(this));
        this.dropdownInputField.addEventListener('focus', this.onFocus.bind(this));

        this.init();

    }

    init() {

        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('scroll', this.onScroll.bind(this));
    }

    onScroll() {
        this.dropdownInputField.blur();
    }

    onResize() {
        this.dropdownInputField.blur();
    }

    onFocus() {
        this.container.classList.add('active');
        this.dropdownList.classList.add('active');
        if ((this.container.offsetTop) + 220 >= window.innerHeight) {
            //220 - minimum height of dropdown list
            this.dropdownList.classList.add("expandUp");
        } else {
            this.dropdownList.classList.remove("expandUp");
        }
    }

    onBlur() {
        this.dropdownList.classList.remove('active');
        this.container.classList.remove('active');
    }


    onMouseDown(e) {

        let isDropdown = this.dropdownList.contains(e.target);
        let isInput = this.dropdownInputField.contains(e.target);

        if (!isDropdown && !isInput) {
            this.dropdownList.classList.remove('active');
            this.container.classList.remove('active');
        }

        if (e.button === 0) {

            if (e.target.classList.contains('dropdown__item')) {
                this.dropdownInputField.value = e.target.textContent;
            }

        }
    }

}
 