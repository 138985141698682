if($('#y_map').length){
    ymaps.ready(function () {
        var myMap = new ymaps.Map('y_map', {
                center: [55.634674, 37.438988],
                zoom: 16,
                controls: ['smallMapDefaultSet']
            }, {
                searchControlProvider: 'yandex#search'
            }),
    
            // Создаём макет содержимого.
            MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
            ),
    
            myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                hintContent: 'ПРофЭлектро',
                balloonContent: '108811, г. Москва, 22-й км Киевского шоссе (п. Московский), домовл. 4, стр. 2, корпус В, подъезд офисный 16, офис 320в.'
            }, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: 'assets/templates/img/map_baloon.svg',
                // Размеры метки.
                iconImageSize: [100, 41],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-50, -41]
            });
    
        myMap.geoObjects.add(myPlacemark);
    });
}
