// MOVE DYNAMIC BLOCKS



contactItemsContainers = document.querySelectorAll('.contacts__items');


function moveDynamicContactItems() {

    contactItemsContainers.forEach(function (container) {
        moveContactsItemFrom = container.querySelector('.middle-column');
        toFirstColumnItems = container.querySelectorAll('.to-first-column');
        toLastColumnItems = container.querySelectorAll('.to-last-column');
        firstColumn = container.querySelector('.first-column');
        lastColumn = container.querySelector('.last-column');
        moveBlocks(toFirstColumnItems, tabletResolution);
        moveBlocks(toLastColumnItems, tabletResolution);

    });

}


function moveBlocks(blocks, screenWidth) {

    blocks.forEach(function (block) {

        if (window.innerWidth <= screenWidth) {

            if (block.getAttribute('data-moved') === 'false') {

                moveAt = block.getAttribute('data-move-at');
                moveTo = block.getAttribute('data-move-to');

                if (moveTo == 'first') {
                    if (moveAt == 'begin') {
                        firstColumn.prepend(block)
                    } else {
                        firstColumn.append(block)
                    }
                } else {
                    if (moveAt == 'begin') {
                        lastColumn.prepend(block)
                    } else {
                        lastColumn.append(block)
                    }
                }

                block.setAttribute('data-moved', 'true')
            }

        } else {

            if (block.getAttribute('data-moved') === 'true') {

                moveContactsItemFrom.append(block);
                block.setAttribute('data-moved', 'false')

            }

        }

    });
}


window.addEventListener('optimizedResize', function () {
    moveDynamicContactItems();
});
document.addEventListener("DOMContentLoaded", function () {
    moveDynamicContactItems();
});