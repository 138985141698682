$(document).on('click', 'form.js-orders-item', function () {
  $(this).submit();
  $('.js-orders-list').slideUp(400);
});

$(document).on('click', '.js-orders-back', function (e) {
  e.preventDefault();

  $('.js-orders-order').hide(400, function () {
    $('.js-orders-list').slideDown(400);
  });
});

$('.js-datepicker').datepicker({
  dateFormat: 'dd.mm.yy',
  onSelect: function () {
    $('.js-orders-period_input').val($('.js-orders-period_input').data('text'));
    $(this).change();
  }
});

$('.js-orders-period_wrapper').on('click', function () {
  $(this).toggleClass('active');
});

$('.js-orders-period').on('click', function () {
  const interval = $(this).data('interval');
  const val = $(this).text();
  const date = new Date();
  const today = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  
  $('.js-orders-period_input').val(val);
  $('.js-datepicker-to').datepicker('setDate', today);

  switch (interval) {
    case 7:
      date.setDate(date.getDate() - 7);
      break;
    
    case 30:
      date.setDate(date.getDate() - 30);
      break;
    
    case 60:
      date.setDate(date.getDate() - 60);
      break;
    
    case 90:
      date.setDate(date.getDate() - 90);
      break;
  }

  const from = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  $('.js-datepicker-from').datepicker('setDate', from);
  $('.js-datepicker').change();
});