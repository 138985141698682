tabletResolution = 992;
phoneResolution = 510;


scrollToTop = document.querySelector(".scroll_top");

scrollToTop.addEventListener("click", function (e) {
    e.preventDefault();

    window.scrollTo(0, 0);
});

window.addEventListener("scroll", function () {
    changeStyleOnScroll(scrollToTop, 'active', 300);

});


$('.lazy_video > div').click(function(e){
    e.preventDefault();
    let picture = $(this).children('picture');
    let iframe = $(this).children('iframe');
    let videoInfo = $(this).children('.video-info');
    let playButton = $(this);
    
    playButton.addClass('inactive');
    picture.removeClass('active');
    videoInfo.hide();
    iframe.addClass('active');
    iframe.attr('src', iframe.data('src'));
});


function changeStyleOnScroll(element, style, pxToTrigger) {
     
    if (document.body.scrollTop > pxToTrigger || document.documentElement.scrollTop > pxToTrigger) {
        if (!element.classList.contains(style)) {
            element.classList.add(style);
        }
    } else {
        if (element.classList.contains(style)) {
            element.classList.remove(style);
        }
    }
}


function toggleClass(element, className) {
    element.classList.toggle(className);
}


function removeHash() {
    history.pushState("", document.title, window.location.pathname
        + window.location.search);
}
removeHash();



(function () {
    var throttle = function (type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function () {
            if (running) { return; }
            running = true;
            requestAnimationFrame(function () {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle("resize", "optimizedResize");
})();



sal({
    threshold: 0.05,
    once: true,
});

$('ul.tabs__caption').on('click', 'li:not(.active)', function() {
    $(this)
        .addClass('active').siblings().removeClass('active')
        .closest('div.tabs').find('div.tabs__content').removeClass('active').addClass('hide').eq($(this).index()).addClass('active').removeClass('hide');

    // projectSlider = document.querySelectorAll('.active .projects__slider');
    // if (projectSlider) {
    //     projectSlider.forEach(function (project) {
    //         new Splide(project, {
    //             type: "loop",
    //             gap: 30,
    //             perPage: 2,
    //             drag: false,
    //             clones: 0,
    //             breakpoints: {
    //                 767: {
    //                     gap: 10,
    //                     perPage: 1,
    //                     drag: true
    //                 }
    //             }
    //         }).mount();
    //     });
    // }

    // if (window.innerWidth <= phoneResolution) {
    //     projectSliderMin = document.querySelectorAll('.active .projects__slider-min');
    //     if (projectSliderMin) {
    //         projectSliderMin.forEach(function (project) {
    //             new Splide(project, {
    //                 type: "loop",
    //                 gap: 0,
    //                 perPage: 1,
    //                 drag: true,
    //                 arrows: false,
    //                 clones: 0,
    //             }).mount();
    //         });
    //     }
    // }

});

// read more button
$(".js-review-button__show").click(function(){
    var readMoreText = "Развернуть отзыв";
    var readMoreHide = "Свернуть отзыв";
    var reviewText = $(this).parents(".block-review").find(".show-more_wrap");
    
    reviewText.toggleClass("active");
    if($(this).children('span').text() === readMoreText){
        $(this).children('span').text(readMoreHide);
    } else {
        $(this).children('span').text(readMoreText);
    }
});