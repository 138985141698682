var rangeSlider = document.querySelector('.price-filter__slider');

if (rangeSlider) {
    minInput = document.querySelector('.min-input');
    maxInput = document.querySelector('.max-input');

    let min = parseInt(minInput.value);
    let max = parseInt(maxInput.value);

    noUiSlider.create(rangeSlider, {

        start: [min, max],
        connect: true,
        range: {
            'min': min,
            'max': max
        },
        //margin: 0,
        format: {
            to: function (value) {
                return parseInt(value);
            },
            from: function (value) {
                return parseInt(value);
            }
        }
    });

    

    rangeSlider.noUiSlider.on('update', function (values, handle) {
        var value = values[handle];

        if (handle) {
            maxInput.value = value;
            $(maxInput).change();
        } else {
            minInput.value = value;
            $(minInput).change();
        }
    });


    minInput.addEventListener('change', function () {
        rangeSlider.noUiSlider.set([this.value, null]);
    });

    maxInput.addEventListener('change', function () {
        rangeSlider.noUiSlider.set([null, this.value]);
    });
}