$('.js-fav').on('click', function () {
  const $self = $(this);
  const id = parseInt($(this).data('id'));
  let total = parseInt($('.js-fav-total').text());

  $(this).hide(300);

  if ($(this).hasClass('added')) {

    $.post(document.location.href, {
      action: 'fav_remove',
      product: id
    }, function (response) {
      if (response.success) {
        $self.removeClass('added');
        $('.js-fav-total').text(total - 1);
        $self.show(100);
        //$('.js-modal-message').text('Товар удалён из избранного');
        //showModal('js-modal');
      }
    }, 'json');

  } else {

    $.post(document.location.href, {
      action: 'fav_add',
      product: id
    }, function (response) {
      if (response.success) {
        $self.addClass('added');
        $('.js-fav-total').text(total + 1);
        $self.show(100);
        //$('.js-modal-message').text('Товар добавлен в избранное');
        //showModal('js-modal');
      }
    }, 'json');

  }
});

$('.js-fav-remove').on('click', function (e) {
  e.preventDefault();
  
  const id = parseInt($(this).data('id'));
  const $card = $('.js-fav-card[data-id="' + id + '"]');
  let total = parseInt($('.js-fav-total').text());

  $.post(document.location.href, {
    action: 'fav_remove',
    product: id
  }, function (response) {
    if (response.success) {
      $card.remove();
      $('.js-fav-total').text(total - 1);
    }
  }, 'json');
});

$('.js-fav-search').on('submit', function (e) {
  e.preventDefault();

  // массив классов для поиска
  const config = [
    '.js-fav-name',
    '.js-fav-code',
    '.js-fav-brend',
    '.js-fav-article'
  ];
  const $input = $('.js-fav-input');
  const search = $input.val().toLowerCase();

  $input.blur();
  $('.js-fav-card').each(function () {
    let $self = $(this);
    let found = false;

    config.some(function (elementClass) {
      if ( $self.find(elementClass).text().toLowerCase().includes(search) ) {
        found = true;
        return true;
      }
    });

    if (found) $self.show(400);
    else $self.hide(400);
  });
});