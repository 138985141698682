// popup-submenu trigger
popupSubmenuTriggerButtons = document.querySelectorAll('.popup-submenu-trigger');
popupSubmenuTriggerButtons.forEach(function (button) {
    button.addEventListener("click", function (e) {

        e.preventDefault();
        toggleClass(this, 'active');

    })
});

$(document).on('click', '.top-bar .popup-submenu__link', function () {
    document.location.href = $(this).attr('href');
});

// close popup-submenus if clicked outside
window.addEventListener('click', function (e) {

    trigger = e.target.closest('.popup-submenu-trigger');
    if (!trigger) {
        popupSubmenuTriggerButtons.forEach(function (button) {
            button.classList.remove('active')
        })
    }
});
// END close popup-submenus if clicked outside

// END popup-submenu trigger