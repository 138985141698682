alphabetAccordionTitles = document.querySelectorAll('.alphabet__details-title');
if (alphabetAccordionTitles)
    alphabetAccordionTitles.forEach(function (title) {
        title.addEventListener("click", function (e) {
            if (window.innerWidth <= phoneResolution) {
                parent = e.target.closest('.accordion');
                toggleClass(parent, "active");
            }
        })
    });


filterAccordionTitles = document.querySelectorAll('.filter .accordion__title');
if (filterAccordionTitles)
    filterAccordionTitles.forEach(function (title) {
        title.addEventListener("click", function (e) {
            // if (window.innerWidth < phoneResolution) {
            parent = e.target.closest('.accordion');
            toggleClass(parent, "active");
            // }
        })
    });

accountAccordionTitle = document.querySelector('.account .accordion__title');
if (accountAccordionTitle)
    accountAccordionTitle.addEventListener("click", function (e) {
        if (window.innerWidth < tabletResolution) {
            parent = e.target.closest('.accordion');
            toggleClass(parent, "active");
        }
    })


serfAccordionTitle = document.querySelectorAll('.sec-serf .accordion--vacancy > .accordion__title');
if (serfAccordionTitle)
    serfAccordionTitle.forEach(function (title) {
        title.addEventListener("click", function (e) {
            parent = e.target.closest('.accordion');
            toggleClass(parent, "active");
        })
    });

serfAccordionTitle = document.querySelectorAll('.sec-serf .accordion--vacCity > .accordion__title');
if (serfAccordionTitle)
    serfAccordionTitle.forEach(function (title) {
        title.addEventListener("click", function (e) {
            parent = e.target.closest('.accordion');
            toggleClass(parent, "active");
        })
    });

calcAccordionTitle = document.querySelector('.calc .accordion__title');
if (calcAccordionTitle)
    calcAccordionTitle.addEventListener("click", function (e) {
        if (window.innerWidth < tabletResolution) {
            parent = e.target.closest('.accordion');
            toggleClass(parent, "active");
        }
    })