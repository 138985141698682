 
popupTooltipTriggerInfoButtons = document.querySelectorAll('.popup-tooltip-trigger.info');
popupTooltipTriggerButtons = document.querySelectorAll('.popup-tooltip-trigger');

popupTooltipTriggerInfoButtons.forEach(function (button) {
    button.addEventListener("click", function (e) {

        e.preventDefault();

        popupTooltipTriggerInfoButtons.forEach(function (button) {
            button.classList.remove('active')
        })

        toggleClass(this, 'active');

    })
});


popupTooltipTriggerButtons.forEach(function (button) {
    button.addEventListener("mouseenter", function () {

        currentTooltip = this.querySelector('.popup-tooltip');
        currentTooltipOffset = button.getBoundingClientRect().left + (currentTooltip.clientWidth);
     
        splideSliderBoundary = this.closest('.splide__track');
 

        if (splideSliderBoundary) {
            splideOffset = splideSliderBoundary.getBoundingClientRect();

            if (currentTooltipOffset > splideOffset.right) {
                this.setAttribute('data-direction', 'right')
            } else if (currentTooltipOffset < splideOffset.left) {
                this.setAttribute('data-direction', 'left')
            } else if (currentTooltipOffset > splideOffset.left && currentTooltipOffset < splideOffset.right) {
                this.setAttribute('data-direction', 'center')
            }
        } else {

            if (currentTooltipOffset > window.innerWidth) {
                this.setAttribute('data-direction', 'right')
            } else if (currentTooltipOffset < 0) {
                this.setAttribute('data-direction', 'left')
            } else if (currentTooltipOffset > 0 && currentTooltipOffset < window.innerWidth) {
                this.setAttribute('data-direction', 'center')
            }

        }
    })
});

// close popup-tooltip if clicked outside
window.addEventListener('click', function (e) {

    trigger = e.target.closest('.popup-tooltip-trigger');
    if (!trigger) {
        popupTooltipTriggerButtons.forEach(function (button) {
            button.classList.remove('active')
        })
    }
});
// END close popup-tooltip if clicked outside

 