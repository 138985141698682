modals = document.querySelectorAll('.modal');

// filter modal
filterModalButton = document.querySelector('.filter__modal-button');
filterContainer = document.querySelector('.search-result__filter');
sidebar = document.querySelector('.sidebar');

if (filterModalButton) {
    filterModalButton.addEventListener('click', function () {
        showModal('filter-modal');
    });
}
//END  filter modal


//avialable in stock modal buttons
availableInStockButtons = document.querySelectorAll('.product-instock-link');
if (availableInStockButtons) {
    availableInStockButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            showModal("in-stock-modal");
        });
    });
}
//

// city select modal
receiptPlaceButtons = document.querySelectorAll('.receipt-place__button');
if (receiptPlaceButtons) {

    receiptPlaceButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            showModal("receipt-place-modal");
        });
    });
}

$('.js-city-change').on('click', function (e) {
    let id = $(this).data('id'),
        city = $(this).data('city'),
        phone = $(this).data('phone'),
        href_tel = $(this).data('phone').replace('/[\s\(\)-]/', '');

    e.preventDefault();

    $.post(document.location.href, { action: 'city_change', city_id: id }, function (data) {
        // reload if contacts page
        let response = JSON.parse(data);
        if (response.reload) {
            document.location.reload();
        } else {
            if ($('input[name="city_id"]')) $('input[name="city_id"]').val(id);
            $('.js-city').text(city);
            $('.js-phone').text(phone);
            $('.js-phone-preg').attr('href', 'tel:' + href_tel);
            hideModal(document.querySelector('.modal.active'));
        }
    });
});

$('.js-city-search').on('submit', function (e) {
    e.preventDefault();
  
    const $input = $('.js-city-input');
    const search = $input.val().toLowerCase();
  
    $input.blur();
    
    $('.js-city-letter').each(function () {
        let $list = $(this).find('.js-city-list');
        let total = $list.children().length;
        let hidden = 0;

        $list.children().each(function () {
            if ($(this).text().toLowerCase().includes(search)) {
                $(this).show(300);
            } else {
                hidden += 1;
                $(this).hide(300);
            }
        });

        if (total == hidden) {
            $(this).hide(300);
            $(this).removeClass('active');
        } else {
            if (search) {
                $(this).addClass('active');
            }
            $(this).show(300);
        }
    });
  });
//END city select modal


$('.js-feedback-open').on('click', function(){
    showModal('js-modal-feedback');
});

$('.js-feedback_work-open').on('click', function(){
    var profession = $(this).parents('.accordion--serf').find('.title').text();
    $('.js-modal-feedback_work input[name="profession"]').val(profession);
    $('.js-modal-feedback_work input[name="hr"]').val('hr@p-el.ru');
    showModal('js-modal-feedback_work');
});

$('.js-feedback_hr-open').on('click', function(){
    var hr_email = $(this).parent('.block-manager').find('.block-manager__email').text();
    $('.js-modal-feedback_work input[name="hr"]').val(hr_email);
    console.log(hr_email);
    showModal('js-modal-feedback_work');
});

$('.js-review-open').on('click', function(){
    showModal('js-modal-review');
});

$('.js-partner-open').on('click', function(){
    showModal('js-modal-partner');
});

// прикрепление файлов к форме
$('input.file_upload_items').MultiFile({
    max: 6,
    accept: 'pdf|doc|docx',
    STRING: {
        remove: '',
        denied:'Выбранный тип файла (.$ext) не доступен для загрузки! Выберите .pdf, .doc или .docx', 
        duplicate:'Этот файл уже прикреплён:\n$file!'
    }
});

$('.upload_files_button').click(function(){
  $(this).siblings('.MultiFile-wrap').find('input').last().click();
});


//reg modal
regButtons = document.querySelectorAll('.reg-button:not(input)');
if (regButtons) {
    regButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            showModal('reg-modal');
        });
    });
}
//END reg modal

// order checkout modal
// orderCheckoutButton = document.querySelector('.cart-checkout-button');
// if (orderCheckoutButton) {

//     orderCheckoutButton.addEventListener('click', function () {
//         showModal('order-checkout-modal');
//     });

// }
// end order checkout modal

// login modal
loginButtons = document.querySelectorAll('.login-button');


if (loginButtons) {
    loginButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            showModal('login-modal');

            loginFormContainers = document.querySelectorAll('.login-modal .modal-login-form');
            restoreFormContainer = document.querySelectorAll('.login-modal .modal-restore-form');

            loginFormContainers.forEach(function (element) {
                element.classList.remove('d-none');
            });
            restoreFormContainer.forEach(function (element) {
                element.classList.add('d-none');
            });
        });
    });
}


/*orgPasswordRestoreButton = document.querySelector('.organization-password-forgot');
orgPasswordRestoreButton.addEventListener('click', function () {

    loginFormContainer = document.querySelector('.organization .modal-login-form');
    loginFormContainer.classList.add('d-none');

    restoreFormContainer = document.querySelector('.organization .modal-restore-form');
    restoreFormContainer.classList.remove('d-none');
});

privatePasswordRestoreButton = document.querySelector('.private-password-forgot');
privatePasswordRestoreButton.addEventListener('click', function () {

    loginFormContainer = document.querySelector('.private .modal-login-form');
    loginFormContainer.classList.add('d-none');

    restoreFormContainer = document.querySelector('.private .modal-restore-form');
    restoreFormContainer.classList.remove('d-none');
});


orgPasswordRestoreButton = document.querySelector('.organization .restore-password-button');
orgPasswordRestoreButton.addEventListener('click', function (e) {
    e.preventDefault();

    console.log('organization restore-password-button');


});


passwordRestoreButton = document.querySelector('.private .restore-password-button');
passwordRestoreButton.addEventListener('click', function (e) {
    e.preventDefault();

    currentRestoreStep = this.getAttribute('data-step');

    if (currentRestoreStep === "1") {
        step2Container = document.querySelector('.private .step2');
        step2Container.classList.add('active');

        SMSTimer = new Date(Date.parse(new Date()) + 1 * 1 * 5 * 60 * 1000); // 5 minutes
        initializeClock(SMSTimer);

        this.setAttribute("data-step", "2")

    } else if (currentRestoreStep === "2") {
        step3Container = document.querySelector('.private .step3');
        step3Container.classList.add('active');
        this.setAttribute("data-step", "3")

    }
    else if (currentRestoreStep === "3") {

        showModal("password-changed-modal");

    }

});*/


//END login modal


function showModal(modalName) {
    modals.forEach(function (modal) {
        if (modal.classList.contains(modalName)) {
            modal.classList.add("active")
            document.querySelector("body").classList.add('lock');
            //для модалки фильтра
            if (modal.classList.contains('filter-modal')) {
                modalMainContent = modal.querySelector('.modal__content-main');
                modalMainContent.append(filterContainer);
            }
            //
        }
    });

}

function hideModal(modalName) {
    modalName.classList.remove("active");
    document.querySelector("body").classList.remove('lock');
}

// close modals
window.addEventListener('click', function (e) {

    if (e.target.classList.contains('modal') || e.target.classList.contains('modal__close-button')) {
        modal = e.target.closest('.modal');

        hideModal(modal);

        // для модалки фильтра
        if (modal.classList.contains('filter-modal')) {
            modalMainContent = modal.querySelector('.modal__content-main');
            if (modalMainContent && sidebar) {
                modalMainContent.innerHTML = '';
                sidebar.prepend(filterContainer);
            }
        }
        //////

    }


});
// END close modals