
allTabs = document.querySelectorAll(".tab");
tabsListContainer = document.querySelector(".tabs__list");
tabs = document.querySelectorAll(".tab__title");



tabs.forEach(function (tab) {
    tab.addEventListener("click", function () {
        toggleTab(this);
        resizeTabContent(parent);
    });
});

function toggleTab(tab) {

    if (window.innerWidth < tabletResolution) {
        marginBottom = 0;
    } else
        marginBottom = 0;

    parent = tab.closest('.tabs__item');
    if (!parent.classList.contains('active')) {
        allTabs.forEach(function (tab) {
            tab.classList.remove('active');
            tab.style.marginBottom = `${marginBottom}px`;
        });

        // window.scrollTo(0, parent.offsetTop + ( parent.clientHeight));// Либо переделать, либо удалить
        
        parent.classList.add('active')
    } else {
        parent.classList.remove('active');
        parent.style.marginBottom = `${marginBottom}px`;
    }
}



window.addEventListener("optimizedResize", function () {
    resizeTabContent();
});

function resizeTabContent(parent) {

    // activeTabsContent = document.querySelector(".tab.active .tab__content");
    tabContent = document.querySelector(".tab.active .tab__content");

    if (window.innerWidth < tabletResolution) {
        marginBottomOffset = 1;
    } else
        marginBottomOffset = 1;

    if (tabContent) {

        if (!parent)
            parent = tabContent.closest(".tab.active")
        // tabContent = parent.querySelector(".tab__content");


        tabContent.style.width = `${tabsListContainer.clientWidth}px`;
        tabContent.style.left = `-${parent.offsetLeft}px`;
        tabContent.style.top = `${parent.clientHeight}px`;
        parent.style.marginBottom = `${tabContent.clientHeight + marginBottomOffset}px`;

    }


}
