sortByInput = document.querySelector('.sortby-dropdown__input');
dropdownItems = document.querySelectorAll('.sortby-dropdown__item');

dropdownContent = document.querySelector('.sortby-dropdown__content');

if (dropdownContent) {

    dropdownContent.addEventListener("click", function () {
        if (window.innerWidth <= phoneResolution) {
            dropdown = this.closest('.sortby-dropdown');
            dropdown.classList.toggle('active');
        }
    });


    dropdownItems.forEach(function(item) {

        item.addEventListener("click", function (e) {
            e.preventDefault();

            itemSortDir = this.getAttribute("data-dir");


            if (this.classList.contains('active')) {

                if (itemSortDir == "asc") {
                    this.setAttribute('data-dir', 'desc')
                    sortByInput.setAttribute("data-dir", 'desc');
                } else {
                    this.setAttribute('data-dir', 'asc')
                    sortByInput.setAttribute("data-dir", 'asc');
                }

            } else {

                // dropdownItems.forEach(function (item) {
                //     item.classList.remove('active');
                // });
                $('.js-compare-wrapper.active .js-compare-sort').removeClass('active');

                currentItem = this.querySelector('span');
                sortByInput.value = currentItem.innerHTML;

                sortByInput.setAttribute("data-dir", itemSortDir);
                this.classList.add('active');

            }



        })

    });
}

$('.js-sortby-reset').on('click', function (e) {
    e.preventDefault();

    const url = window.location.origin + window.location.pathname;
    const get = window.location.search.replace('?', '');
    let arrGet = new Array();

    if (get.length) {
        const requests = get.split('&');

        requests.forEach(function (el) {
            let request = el.split('=');

            if (requests.length == 1 && request[0] == 'sort') {
                return window.location.href = url;
            } else if (request[0] != 'sort') {
                arrGet.push(el);
            }
        });

        window.location.href = url + '?' + arrGet.join('&');
    }
});


// пришлось прикручивать костыль
$('#mse2_sort .sort').on('click',function () {
    const val = $(this).text().trim();
    const dir = $(this).data('dir') == 'asc' ? 'desc' : 'asc';

    $('.js-sortby-input').attr('data-dir', dir).val(val);
});