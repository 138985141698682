estimateNameCharsRemain = document.querySelector('.estimate-name-chars');
estimateNameInput = document.querySelector('.estimate-name-input');

if (estimateNameCharsRemain) {
    
    document.addEventListener("DOMContentLoaded", function () {
        charsRemain = estimateNameInput.getAttribute('maxlength');
        estimateNameCharsRemain.innerHTML = (charsRemain - estimateNameInput.value.length);
    });

    estimateNameInput.addEventListener("input", function () {
        estimateNameCharsRemain.innerHTML = (charsRemain - this.value.length);
    });


}

$('.js-outlay-add').on('click', function () {
    let smeta = new Array;

    $('.js-cart-count').each(function () {
        let id = parseInt($(this).data('id'));
        let quantity = parseInt($(this).val());

        smeta.push({
            product: id,
            count: quantity
        });
    });

    smeta = JSON.stringify(smeta);

    $('.js-outlay-name').data('outlay', smeta);
    $('.js-outlay-name').data('action', 'new');
    showModal('js-modal-outlay-name');
});

$('.js-outlay-name').on('submit', function (e) {
    e.preventDefault();

    const action = $(this).data('action');
    const name = $('.js-outlay-name-input').val();
    hideModal(document.querySelector('.js-modal-outlay-name'));

    if (action === 'new') {
        const smeta = $(this).data('outlay');
    
        showModal('js-preloader');
        $.post(document.location.href, {
            action: 'outlay_add',
            products: smeta,
            name: name
        }, function (response) {
            hideModal(document.querySelector('.js-preloader'));

            if (response.success) {
                $('.js-modal-message').text('Новая смета успешно сохранена');
                showModal('js-modal');
            } else {
                $('.js-modal-message').text('Ошибка сохранения новой сметы');
                showModal('js-modal-error');
            }
        }, 'json');
    }

    if (action === 'rename') {
        const outlay = $(this).data('outlay');
    
        showModal('js-preloader');
        $.post(document.location.href, {
            action: 'outlay_rename',
            name: name,
            outlay: outlay
        }, function (response) {
            hideModal(document.querySelector('.js-preloader'));

            if (response.success) {
                document.location.reload();
            } else {
                $('.js-modal-message').text('Ошибка переименования сметы');
                showModal('js-modal-error');
            }
        }, 'json');
    }
});

$('.js-estimates-link').on('click', function (e) {
    e.preventDefault();

    const url = $(this).attr('href');
    const idx = $(this).data('idx');

    $.post(document.location.href, {
        action: 'outlay_set',
        idx: idx
    }, function (response) {
        if (response.success) {
            document.location.href = url;
        } else {
            $('.js-modal-message').text('Произошла ошибка. Не могу загрузить смету.');
            showModal('js-modal-error');
        }
    }, 'json');
});

$('.js-outlay-action').on('click', function () {
    const action = $(this).data('action');
    const url = $(this).data('url');
    const inner = $('.js-outlay-radio').length ? false : true;
    let outlay = null;
    let name = null;
    let products = null;

    if (!inner) {

        if (!$('.js-outlay-radio:checked').length) {
            $('.js-modal-message').text('Для проведения операции нужно выбрать смету.');
            showModal('js-modal');
            return false
        } else {
            outlay = $('.js-outlay-radio:checked').data('id');
            name = $('.js-outlay-radio:checked').data('name');
            products = $('.js-outlay-radio:checked').data('products');
        }
            
    } else {
        outlay = $('.js-outlay-data').data('outlay');
        name = $('.js-outlay-data').data('name');
        products = $('.js-outlay-data').data('products');
    }


    switch (action) {
        case 'buy':
            if (products === 'false') {
                $('.js-modal-message').text('В данной смете нет товаров.');
                showModal('js-modal');
                return false;
            }

            showModal('js-preloader');
            $.post(document.location.href, {
                action: 'outlay_to_cart',
                products: products
            }, function (response) {
                hideModal(document.querySelector('.js-preloader'));

                if (response.success) {
                    document.location.href = url;
                } else {
                    $('.js-modal-message').text('Произошла ошибка. Не могу добавить товары из сметы в корзину.');
                    showModal('js-modal-error');
                }
            }, 'json');
            
            break;
        
        case 'copy':
        showModal('js-preloader');    
        $.post(document.location.href, {
                action: 'outlay_copy',
                outlay: outlay
            }, function (response) {
                hideModal(document.querySelector('.js-preloader'));

                if (response.success) {
                    document.location.reload();
                } else {
                    $('.js-modal-message').text('Произошла ошибка. Не могу копировать смету.');
                    showModal('js-modal-error');
                }
            }, 'json');
            
            break;
        
        case 'delete':
            showModal('js-preloader');    
            $.post(document.location.href, {
                    action: 'outlay_remove',
                    outlay: outlay
                }, function (response) {
                    hideModal(document.querySelector('.js-preloader'));
    
                    if (response.success) {
                        document.location.href = url;
                    } else {
                        $('.js-modal-message').text('Произошла ошибка. Не могу копировать смету.');
                        showModal('js-modal-error');
                    }
                }, 'json');
                
                break;
            
            break;
        
        case 'edit':
            $('.js-outlay-name-input').val(name);
            $('.js-outlay-name').data('outlay', outlay);
            $('.js-outlay-name').data('action', 'rename');
            showModal('js-modal-outlay-name');
            
            break;
        
        case 'share':
        showModal('js-preloader');    
        $.post(document.location.href, {
                action: 'outlay_print',
                outlay: outlay
            }, function (response) {
                hideModal(document.querySelector('.js-preloader'));

                if (response.success) {
                    $('.js-share-link').val(response.url);
                    $('.js-outlay-share-input').data('file', response.path);
                    showModal('js-modal-share');
                } else {
                    $('.js-modal-message').text('Произошла ошибка. Не могу копировать смету.');
                    showModal('js-modal-error');
                }
            }, 'json');
            
            break;
        
        case 'download':
        showModal('js-preloader');    
        $.post(document.location.href, {
                action: 'outlay_print',
                outlay: outlay
            }, function (response) {
                hideModal(document.querySelector('.js-preloader'));

                if (response.success) {
                    const $link = $('<a href="' + response.path + '" download="' + response.name + '">Cкачать ' + name + '</a>');
                    $('.js-modal-message').html($link);
                    showModal('js-modal');
                } else {
                    $('.js-modal-message').text('Произошла ошибка. Не могу загрузить смету.');
                    showModal('js-modal-error');
                }
            }, 'json');
            
            break;
    }
});

$('.js-outlay-remove-product').on('click', function () {
    const outlay = $(this).data('outlay');
    const product = $(this).data('product');

    showModal('js-preloader');
    $.post(document.location.href, {
        action: 'outlay_product_remove',
        outlay: outlay,
        product: product
    }, function (response) {
        hideModal(document.querySelector('.js-preloader'));

        if (response.success) {
            document.location.reload();
        } else {
            $('.js-modal-message').text('Произошла ошибка. Не могу удалить товар из сметы.');
            showModal('js-modal-error');
        }
    }, 'json');
});

$('.js-outlay-work-add').on('click', function () {
    const outlay = $(this).data('outlay');
    const name = $('.js-outlay-works-name').val();
    const price = parseFloat( $('.js-outlay-works-price').val().replace(',', '.') );
    const count = $('.js-outlay-works-count').val();

    if (!name) {
        $('.js-modal-message').text('Введите название работ.');
        showModal('js-modal');
        return false;
    }
    if (!price) {
        $('.js-modal-message').text('Введите стоимость работ.');
        showModal('js-modal');
        return false;
    }

    showModal('js-preloader');
    $.post(document.location.href, {
        action: 'outlay_work_add',
        outlay: outlay,
        name: name,
        price: price,
        count: count
    }, function (response) {
        hideModal(document.querySelector('.js-preloader'));

        if (response.success) {
            document.location.reload();
        } else {
            $('.js-modal-message').text('Произошла ошибка. Не могу добавить работы к смете.');
            showModal('js-modal-error');
        }
    }, 'json');
});

$('.js-outlay-work-remove').on('click', function () {
    const outlay = $(this).data('outlay');
    const work = $(this).data('work');

    showModal('js-preloader');
    $.post(document.location.href, {
        action: 'outlay_work_remove',
        outlay: outlay,
        work: work
    }, function (response) {
        hideModal(document.querySelector('.js-preloader'));

        if (response.success) {
            document.location.reload();
        } else {
            $('.js-modal-message').text('Произошла ошибка. Не могу удалить работу из сметы.');
            showModal('js-modal-error');
        }
    }, 'json');
});

$('.js-share-copy').on('click', function () {
    const link = document.querySelector('.js-share-link');

    link.select();
    document.execCommand("copy");
});

$('.js-outlay-share').on('submit', function (e) {
    e.preventDefault();

    const email = $('.js-outlay-share-input').val();
    const path = $('.js-outlay-share-input').data('file');
    const $error = $('.js-outlay-share-error');

    if (!validateEmail(email)) {
        $error.addClass('active');
    } else {
        hideModal(document.querySelector('.js-modal-share'));
        showModal('js-preloader');

        $.post(document.location.href, {
            action: 'outlay_send',
            email: email,
            path: path
        }, function (response) {
            hideModal(document.querySelector('.js-preloader'));

            if (response.success) {
                $('.js-modal-message').text('Смета успешно отправлена!');
                showModal('js-modal');
            } else {
                $('.js-modal-message').text('Произошла ошибка. Не могу отправить письмо на указанный адрес.');
                showModal('js-modal-error');
            }
        }, 'json');
    }
});

$('.js-outlay-share-input').on('input', function () {
    const $error = $('.js-outlay-share-error');
    
    if ($error.hasClass('active')) {
        $error.removeClass('active');
    }
});

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}