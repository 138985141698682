passwordEyes = document.querySelectorAll('.password-eye');

if (passwordEyes.length > 0) {


    passwordEyes.forEach(eye => {

        eye.addEventListener('click', function () {

            this.classList.toggle('active');
            field = this.closest('.field');
            passwordInput = field.querySelector('input[name="password"]');
            if (passwordInput.getAttribute('type') == "password") {
                passwordInput.setAttribute('type', 'text')
            } else {
                passwordInput.setAttribute('type', 'password')

            }

        })

    });

}

$('input[type="tel"]').mask('+7 (999) 999-99-99');

$('input').on('input', function (e) {
    if ($(this).hasClass('invalid')) $(this).removeClass('invalid');
});

if ($('.captcha').hasClass('invalid')) {
    document.querySelector('.captcha .input-error-text').scrollIntoView({block: "center", behavior: "smooth"});
}

// $('input[name="lastname"], input[name="firstname"]').on('keydown', function (e) {
//     if (e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 96 && e.keyCode <= 105) {
//         e.preventDefault();
//     }
// });

if($(window).width() <= 510){
    $('.open_search').click(function(){
        $(this).siblings('.brand-search').addClass('active');
    });
    $('.close_search').click(function(){
        $('.right-menu .brand-search').removeClass('active');
    });
}

$('form.login input[name="password"]').on('input', function () {
    let input = $(this).siblings('input[name="auth"]');
    
    if (input.val() == 'r2d2') input.val('c3po');
});

$('form.sendex_form').on('submit', function (e) {
    e.preventDefault();
    $.post(document.location.href, $(this).serialize(), function(response) {
        //console.log($(response).find('.footer__subscription').html());
        if($(response).find('.sendex_message').text()){
            $('.sendex-modal_message').text($(response).find('.sendex_message').text());
        } else {
            $('.sendex-modal_message').text('Вы оформили подписку.');
        }
        showModal('sendex-modal');
    }, "text");
});

// прикрепление файлов к форме
$('input.file_upload_items').each(function(){
    var max = 6;
    var accept = '';
    if($(this).data('max')) {
        max = $(this).data('max');
    }
    accept = $(this).data('accept');
    
    $(this).MultiFile({
        max: max,
        accept: accept,
        STRING: {
            remove: '',
            denied:'Выбранный тип файла (.$ext) не доступен для загрузки! Выберите указанный тип', 
            duplicate:'Этот файл уже прикреплён:\n$file!'
        }
    });
});

// $('input.file_upload_items').MultiFile({
//     max: 10,
//     accept: 'pdf|docx',
//     STRING: {
//         remove: '',
//         denied:'Выбранный тип файла (.$ext) не доступен для загрузки! Выберите .pdf или .docx', 
//         duplicate:'Этот файл уже прикреплён:\n$file!'
//     }
// });

// странно, это было в другом проекте, а здесь почему-то вызывает двойной вызов загрузки файла
//$('.upload_files_button').click(function(){
//  $('input[id^=MultiFile1]').last().click(); 
//});

$(document).on('af_complete', function(event,res) {
    $('.MultiFile-remove').each(function(i){
        $(this).click();
    })
	if (res.success) {
	    // показываем сообщение об успехе
	    if($('.feedback-modal').hasClass('active')){
	        hideModal(document.querySelector('.feedback-modal.active'));
	    }
        showModal('email-sent-modal');
    } else {
         // закрывать ничего не надо, ибо ошибка и юзер должен её исправлять
    }
});

$('form.login').on('submit', function (e) {
    e.preventDefault();

    let self = this;

    $.post(document.location.href, $(this).serialize(), function (response) {
        if (response.status == 'success') {

            if (response.reload) {
                document.location.reload();
            } else {
                document.location.href = $(self).data('url');
            }

        } else {
            let $input = $(self).find('input[name="password"]');

            $input.addClass('invalid');
            $input.siblings('.input-error-text').text(response.message);
        }
    }, "json");
});

$('.js-logout').on('click', function (e) {
    e.preventDefault();

    $.post(document.location.href, { action: "logout" }, function (response) {
        if (response.success) {
            document.location.href = document.location.origin;
        }
    }, 'json');
});

$('form.restore input[name="email"]').on('change', function () {
    $('form.restore input[name="username"]').val($(this).val());
});

/* *** CART *** */
$(document).on('click', '.product__buy-info-button', function() { 
    const $input = $(this).siblings('.product__buy-info-count-input');
    const quantity = parseInt($input.val());
    const $form = $('form[data-key="' + $input.data('key') + '"]');
    let val = 0;

    if ($(this).hasClass('minus-button') && quantity > 1) {
        val = quantity - 1;
        $input.val(val);
    } else if ($(this).hasClass('plus-button')) {
        val = quantity + 1;
        $input.val(val);
    } else {
        return false;
    }

    if ($form.length) $form.find('input[name="count"]').val(val).change();
});

$(document).on('submit', 'form.js-cart-add', function(e) { 
    e.preventDefault();

    const id = $(this).data('id');
    const count = $('input.js-cart-count[data-id="' + id + '"]').val();

    showModal('js-preloader');
    $.post(document.location.href, {
            id: id,
            count: count,
            ms2_action: 'cart/add',
            ctx: 'web'
        }, function (answer) {
            hideModal(document.querySelector('.js-preloader'));
            if (answer.success) {
                let cost = new Intl.NumberFormat().format(answer.data.total_cost);
                $('.msMiniCart .ms2_total_cost').text(cost.replace(',', '.'));
                $('.msMiniCart .ms2_total_count').text(answer.data.total_count);
                showModal('js-modal-cart');
            } else {
                $('.js-modal-message').text('Произошла ошбика добавления товара в корзину');
                showModal('js-modal-error');
            }
        }, 'json'
    );
});

$('.js-cart-checkout').on('click', function () {
    let $form = $('#msOrder').detach();

    $('#msCart').append($form);
    $('.js-cart-inner').hide();
    $form.show();
});

$('.js-promo-code').on('change', function () {
    $('.js-promo-input').val($(this).val()).change();
});