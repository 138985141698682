alphabetCharsLinks = document.querySelectorAll('.alphabet__chars-link');

alphabetCharsLinks.forEach(function (link) {

    link.addEventListener('click', function () {

        alphabetCharsLinks.forEach(function (link) {
            link.classList.remove('active');
        });
        link.classList.add('active');

    });

});
