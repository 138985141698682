compareSliderInit();

// lists tabs
$('.js-compare-select').on('click', function () {
  if (!$(this).hasClass('active')) {
    const list = $(this).data('list');

    $('.js-compare-select').removeClass('active');
    $(this).addClass('active');
    compareSliderDestroy();
    $('.js-compare-wrapper').removeClass('active');
    $('.js-compare-wrapper[data-list="' + list + '"]').addClass('active');
    compareSliderInit();
  }
});

// characteristics tabs
$('.js-compare-sort').on('click', function () {
  if (!$(this).hasClass('active')) {
    const sort = $(this).data('sort');

    switch (sort) {
      case 'all':
        $('.js-compare-wrapper.active .characteristic__item').show(300);
        break;
      
      case 'main':
        $('.js-compare-wrapper.active .characteristic__item.main').show(300);
        $('.js-compare-wrapper.active .characteristic__item:not(.main)').hide(300);
        break;
      
      case 'diff':
        showDifferingCharacteristics();
        break;
      
      default:
        break;
    }
  }
});

// remove from compare single product
$('.js-compare-remove').on('click', function () {
  const list = $(this).data('list');
  const id = $(this).data('id');

  $.post(document.location.href, {
    cmp_action: 'remove',
    list: list,
    resource: id
  }, function (response) {

    if (response.success) {

      if (response.data.total > 0) {
        let total = parseInt($('.js-compare-total').text());

        $('.js-compare-card[data-id="' + id + '"]').remove();
        $('.js-compare-characteristics[data-id="' + id + '"]').remove();
        $('.js-compare-total').text(total - 1);
        $('.js-compare-select.active .number').text(response.data.total);
      } else document.location.reload();
    }

  }, 'json');
});

// remove list from compare
$('.js-compare-delete').on('click', function () {
  const list = $(this).data('list');

  $.post(document.location.href, {
    action: 'compare_list_remove',
    list: list
  }, function (response) {
    if (response.success) {
      document.location.reload();
    }
  }, 'json');
});

function showDifferingCharacteristics() {
  const characteristicList = document.querySelector('.js-compare-wrapper.active .characteristic__list');
  
  $(characteristicList).children('.characteristic__item').each(function () {
    let characteristic = $(this).data('characteristic');
    let $group = $('.js-compare-wrapper.active .characteristic__item[data-characteristic="' + characteristic + '"]');
    let string = $(this).children('.characteristic__value').text();
    let show = false;

    $group.each(function () {
      if ($(this).children('.characteristic__value').text() != string) {
        show = true;
        return false;
      }
    });

    if (show) {
      $group.show(300);
    } else {
      $group.hide(300);
    }
  });
}

// compare sliders
function compareSliderInit() {
  compareSLiderContainer = document.querySelector('.js-compare-wrapper.active .compare__slider');

  if (compareSLiderContainer) {
    compareSLider = new Splide(compareSLiderContainer, {
      type: "slide",
      arrows: false,
      // pagination: true,
      perPage: 5,
      gap: 10,
      breakpoints: {
        1214: {
          perPage: 4
        },
        1010: {
          perPage: 3
        },
        767: {
          perPage: 2
        },
        530: {
          gap: 12,
          perPage: 2,
        }
      }
    }).mount();


    compareCharacteristicSLiderContainer = document.querySelector('.js-compare-wrapper.active .characteristics__slider');
    if (compareCharacteristicSLiderContainer) {
      compareCharacteristicSLider = new Splide(compareCharacteristicSLiderContainer, {
        type: "slide",
        arrows: false,
        pagination: false,
        keyboard: false,
        perPage: 5,
        gap: 0,
        breakpoints: {
          1214: {
            perPage: 4
          },
          1010: {
            perPage: 3
          },
          767: {
            perPage: 2
          },
          530: {
            perPage: 2,
          }
        }
      }).mount();
    }

    compareSLider.on('move', function () {
      compareCharacteristicSLider.go(compareSLider.index);
    });

    // compareCharacteristicSLider.on('move', function () {
    //     compareSLider.go(compareCharacteristicSLider.index);
    // });

  }
}

function compareSliderDestroy() {
  let $compareSLiderContainer = $('.js-compare-wrapper.active .compare__slider');
  let $compareCharacteristicSLiderContainer = $('.js-compare-wrapper.active .characteristics__slider');

  if ($compareSLiderContainer.length > 0 && compareCharacteristicSLiderContainer.length > 0) {
    $compareSLiderContainer.slider('destroy');
    $compareCharacteristicSLiderContainer.slider('destroy');
  }
}
// END compare sliders