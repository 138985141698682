
// MOBILE MENU
menuButton = document.querySelector('.menu-ico');
mobileMenuContainer = document.querySelector('.mobile-menu__container');
menuBlocker = document.querySelector('.menu-blocker');
 

menuBlocker.addEventListener("click", function (e) {
    e.preventDefault();
    toggleMenu();
})
menuButton.addEventListener("click", function (e) {
    e.preventDefault();
    toggleMenu();
})

function toggleMenu() {
    toggleClass(menuButton, 'active');
    toggleClass(mobileMenuContainer, 'active');
    toggleClass(menuBlocker, 'active');
    toggleClass(document.querySelector("body"), 'lock');
   
}
// END MOBILE MENU