estimatesManagerTabs = document.querySelectorAll('.estimates-manager__tabs .tab');

estimatesManagerTabs.forEach(function (tab) {

    tab.addEventListener('click', function (e) {
        e.preventDefault();

        estimatesManagerTabs.forEach(function (tab) {

            tab.classList.remove('active')

        });

        tab.classList.add('active')
    })

});