toReviewLink = document.querySelector('.to-review-link');
tabsContainer = document.querySelector('.product-item__tabs');
reviewsTab = document.querySelector('.reviews-tab');
productItemTabs = document.querySelectorAll('.product-item__tab');

//  открытие таба с отзывами после нажатия на "все отзывы"
if (toReviewLink) {
    toReviewLink.addEventListener('click', function (e) {
        e.preventDefault();
        deactivateAllTabs();
        activateTab(reviewsTab);
        window.scrollTo(0, tabsContainer.offsetTop - 20);
    })
}
//////

if (productItemTabs.length > 0) {
    productItemTabs.forEach(function (tab) {
        tab.addEventListener('click', function (e) {
            if (e.target.classList.contains('product-item__tab-title')) {
                tab = e.target.closest('.product-item__tab');
                if (!tab.classList.contains('active')) {
                    deactivateAllTabs();
                    activateTab(tab);
                    // window.scrollTo(0, tabsContainer.offsetTop - 20);
                } else {
                    activateTab(tab);
                }

            }
        })
    });
}

//показываем форму отзыва
reviewsFormContainer = document.querySelector('.reviews__form-container');
reviewTabButton = document.querySelector('.review-tab-button');

if (reviewTabButton)
    reviewTabButton.addEventListener('click', function () {
        parentTab = reviewTabButton.closest('.tab-feedback');
        parentTab.classList.add('hidden');
        reviewsFormContainer.classList.add('active');
        resizeActiveProductTabContent();
    });
//

//показываем форму вопроса
discussionFormContainer = document.querySelector('.discussion__form-container');
discussionTabButton = document.querySelector('.discussion-tab-button');
if (discussionTabButton)
    discussionTabButton.addEventListener('click', function () {
        parentTab = discussionTabButton.closest('.tab-feedback');
        parentTab.classList.add('hidden');
        discussionFormContainer.classList.add('active');
        resizeActiveProductTabContent();
    });
//

// Как вы оцениваете товар? Меняем значение в инпуте в зависимости от выбранной звезды
ratingStarsSVG = document.querySelectorAll('.tab-feedback-rating  .rating__stars svg');
ratingStars = document.querySelector('.tab-feedback-rating  .rating__stars');
userRatingInput = document.getElementById('user-rating');
currentRating = 0;

ratingStarsSVG.forEach(function (star) {
    star.addEventListener('mouseenter', function () {
        currentRating = ratingStars.getAttribute('data-rating');
        ratingStars.setAttribute('data-rating', getElementIndex(this) + 1);
    })
});

ratingStarsSVG.forEach(function (star) {
    star.addEventListener('mouseleave', function () {
        ratingStars.setAttribute('data-rating', currentRating);
    })
});

ratingStarsSVG.forEach(function (star) {
    star.addEventListener('click', function () {
        currentRating = getElementIndex(this) + 1;
        ratingStars.setAttribute('data-rating', currentRating);
        //userRatingInput.value = currentRating;
    })
});
////////


window.addEventListener("optimizedResize", function () {
    resizeActiveProductTabContent();
});


 


resizeActiveProductTabContent();

function resizeActiveProductTabContent() {

    if (window.innerWidth > phoneResolution) {
        activeTab = document.querySelector('.product-item__tab.active');
        if (activeTab) {
            tabContentHeight = activeTab.querySelector('.product-item__tab-content').clientHeight;
            activeTab.style.minHeight = `${tabContentHeight + 60}px`;
        }
    }
}

function deactivateAllTabs() {
    productItemTabs.forEach(function (tab) {
        tab.style.minHeight = `initial`;
        tab.classList.remove('active');
    });
}

function activateTab(tab) {

    tab.classList.toggle('active');

    if (window.innerWidth > phoneResolution) {
        tab.classList.add('active');
        tabContentHeight = tab.querySelector('.product-item__tab-content').clientHeight;
        tab.style.minHeight = `${tabContentHeight + 60}px`;
    } else {
        tab.style.minHeight = '0px';
    }
}

function getElementIndex(node) {
    index = 0;
    while ((node = node.previousElementSibling)) {
        index++;
    }
    return index;
}