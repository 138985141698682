modalTabs = document.querySelectorAll('.login-modal .modal-tabs .tab');

modalTabs.forEach(function (tab) {

    tab.addEventListener('click', function (e) {
        e.preventDefault();

        modalTabs.forEach(function (tab) {

            tab.classList.remove('active')

        });

        tab.classList.add('active')
    })

});