//  main  baner slider
banerSliders = document.querySelectorAll('.baner__slider');
if (banerSliders.length > 0) {
    banerSliders.forEach(function (baner) {
        new Splide(baner, {
            type: "loop",
        }).mount();
    });

}

// END main baner slider


//  vendor slider
vendorSliders = document.querySelectorAll('.vendor__slider');
if (vendorSliders.length > 0) {
    vendorSliders.forEach(function (vendor) {
        new Splide(vendor, {
            type: "loop",
            gap: 10,
            perPage: 5,
            drag: false,
            breakpoints: {
                980: {
                    perPage: 4,
                    drag: true
                },
                768: {
                    perPage: 3,
                    drag: true
                },
                530: {
                    gap: 12,
                    perPage: 2,
                    drag: true
                }
            }
        }).mount();
    });

}
// END vendor slider


//  serf slider
serfSliders = document.querySelectorAll('.serf__slider');
if (serfSliders.length > 0) {
    serfSliders.forEach(function (serf) {
        new Splide(serf, {
            type: "loop",
            gap: 20,
            perPage: 3,
            arrows: true,
            drag: false,
            breakpoints: {
                530: {
                    gap: 12,
                    perPage: 2,
                    drag: true
                }
            }
        }).mount();
    });

}
// END serf slider

//  reviews slider
reviewsSliders = document.querySelectorAll('.reviews__slider');
if (reviewsSliders.length > 0) {
    reviewsSliders.forEach(function (review) {
        new Splide(review, {
            type: "loop",
            gap: 0,
            perPage: 1,
            arrows: true,
            drag: true
        }).mount();
    });

}
// END reviews slider

//  history slider
historySliders = document.querySelectorAll('.history__slider');
if (historySliders.length > 0) {
    historySliders.forEach(function (history) {
        new Splide(history, {
            type: "loop",
            gap: 20,
            perPage: 1,
            arrows: true,
            drag: true,
            breakpoints: {
                767: {
                    gap: 12,
                    perPage: 1,
                    drag: true
                }
            }
        }).mount();
    });

}
// END history slider


//  project  slider
projectSlider = document.querySelectorAll('.projects__slider');
if (projectSlider) {
    projectSlider.forEach(function (project) {
        if($(project).find('.splide__slide').length) {
            new Splide(project, {
                type: "loop",
                gap: 30,
                perPage: 2,
                drag: true,
                breakpoints: {
                    767: {
                        gap: 10,
                        perPage: 1,
                        drag: true
                    }
                }
            }).mount();
        }
    });
    $('.tabs__content:not(.active)').addClass('hide');
}
// END project slider


// cards slider
cardSliders = document.querySelectorAll('.cards__slider');
if (cardSliders.length > 0) {
    cardSliders.forEach(function (slider) {
        new Splide(slider, {
            type: "slide",
            gap: 10,
            perPage: 5,
            perMove: 1,
            breakpoints: {
                1214: {
                    perPage: 4,
                    gap: 10,
                },
                1010: {
                    perPage: 3,
                    gap: 10,
                },
                767: {
                    perPage: 2,
                    gap: 10,
                },
                530: {
                    gap: 0,
                    perPage: 1
                }
            }
        }).mount();
    })
}
// END cards slider


// product item gallery with thumnails
productItemGalleryContainer = document.querySelector('.product-item-gallery');
if (productItemGalleryContainer) {

    productItemGallery = new Splide(productItemGalleryContainer, {
        type: 'fade',
        height: 304,
        pagination: false,
        arrows: false,
      
    });

    // productItemThumbnailsGalleryContainer = document.querySelector('.product-item-thumbnails');
    // productItemThumbnailsGallery = new Splide(productItemThumbnailsGalleryContainer, {
    //     // fixedWidth: 100,
    //     height: 60,
    //     rewind: true,
    //     gap: 10,
    //     arrows: false,
    //     pagination: false,
    //     // cover: true,
    //     focus: 'center',
    //     // focus: 'center',
    //     isNavigation: true,
    //     perPage: 4,
    // });

    // productItemThumbnailsGallery.mount();
    // productItemGallery.sync(productItemThumbnailsGallery).mount();

    productItemGallery.mount();

    productItemGallery.on('moved', function () {
        currentSlider = document.querySelector('[data-index="' + productItemGallery.index + '"]')
        scrollThumbnails(currentSlider, true);
    });


    thumbnailsWrapper = document.querySelector('.thumbnails-wrapper');
    slidesList = document.querySelector('.product-item-thumbnails__list');
    slides = document.querySelectorAll('.product-item-thumbnails__item');


    lastDir = "left";

    slides.forEach(slide => {
        slide.addEventListener('click', function (e) {

            e.preventDefault();
            scrollThumbnails(this, false);
        })
    });


    function scrollThumbnails(currentSlide, fromSlider) {

        oldIndex = document.querySelector('.product-item-thumbnails__item.active').getAttribute("data-index");

        slides.forEach(slide => {
            slide.classList.remove('active');
        });

        currentSlide.classList.add('active');
        currentIndex = currentSlide.getAttribute("data-index");
        slideWidth = currentSlide.clientWidth;

        if (oldIndex > currentIndex) {

            pxToScroll = (currentIndex - (slides.length - 1)) * slideWidth;

            lastDir = "left";
            console.log(lastDir);

        } else if (oldIndex < currentIndex) {

            pxToScroll = (currentIndex - 1) * slideWidth;

            lastDir = "right";

        } else {
            if (lastDir === "left") {
                pxToScroll = (currentIndex - 1) * slideWidth;
            } else {
                pxToScroll = (currentIndex + 1) * slideWidth;
            }

        }

        thumbnailsWrapper.scroll({
            top: 0,
            left: pxToScroll,
            behavior: 'smooth'
        });

        if (!fromSlider)
            productItemGallery.go(currentIndex);
    }

}
//END  product item gallery with thumnails
